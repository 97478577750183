<template>
  <a-row :gutter="25">
    <!--{{ "dataForm "  +  JSON.stringify(dataForm.dataTableInfo) }}-->
    <a-col 
      :xxl="24" 
      :lg="24" 
      :md="24"
      :xs="24"
      v-if="dataDOM.showLoader">
         <sdCards>
            <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;"/>
        </sdCards>
    </a-col>
    <a-col 
      :xxl="24" 
      :lg="24" 
      :md="24"
      :xs="24"
      v-if="!dataDOM.showLoader">
      <div v-for="section in dataDOM.sections" :key="section">
        
        <a-row :gutter="25" class="ml-2 mt-2" v-if="section.title == 'Sedes'">
          <a-col class="card-fields" :xxl="12" :lg="12" :md="24" :xs="24">
            <span class="field-title">Sedes</span>
            <a-col v-for="(sede, index) in section.fields" :key="sede"
              :xxl="24" :lg="24" :md="24" :xs="24" class="card-fields">
              <span class="title-normal">#{{index + 1}}</span><br>
              <div v-for="element in sede" :key="element">
                <div v-if="element.value != null">
                  <span class="">{{element.label}}</span><br>
                  <span class="field-text-value">{{element.value}}</span>
                </div>
              </div>
            </a-col>
          </a-col>
          <a-col class="card-fields" :xxl="12" :lg="12" :md="24" :xs="24">
            <span class="field-title">Archivos de la convocatoria</span>
            <a-col v-for="field in section.fieldsFiles" :key="field"
              :xxl="24" :lg="24" :md="24" :xs="24" class="card-fields">
              <a class="field-title text-file" href="#" @click="handleDownloadFile(field)">
                <sdFeatherIcons type="file" style="color:#E8394D;" />
                {{field.name}}
              </a>
            </a-col>
          </a-col>
        </a-row>

        <a-row :gutter="25" class="ml-2 mt-2" v-else>
          <a-col  v-for="field in section.fields" :key="field"
            :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields mt-2">
            <span class="field-title">{{field.label}}</span><br>
            <span class="field-text-value">{{field.value}}</span>
          </a-col>
        </a-row>

      </div>
      <br>
      <a-row :gutter="25" v-if="dataDOM.dynamicForm != null">
        <a-col 
          :xxl="24"
          :lg="24" 
          :md="24"
          :xs="24">
          <button class="button-normal" v-on:click="handleWatchDinamicForm">
            <span class="title-normal" v-if="!dataDOM.wathcDinamicForm">Ver formulario dinámico</span>
            <span class="title-normal" v-if="dataDOM.wathcDinamicForm">Ocultar formulario dinámico</span>
          </button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
<script>

import { reactive, /*defineAsyncComponent*/ } from 'vue';
//import { useRouter } from "vue-router";
import { getFileDetail } from '@/services/events'

//const DataTable = defineAsyncComponent(() => import('../../components/DataTable/DataTable.vue'));


const ValidationEvents = {
  name: 'ValidationEventsDetailsComponent',
  components: {
  },
  emits: ['emitShowDinamicForm'],
  props: {
    event: null,
    dynamicForm: null
  },
  async setup(props, { emit }) {
    
    var dataDOM = reactive({
      showLoader: false,
      sections: props.event,
      base_url: process.env.VUE_APP_BASE_URL,
      wathcDinamicForm: false,
      dynamicForm: props.dynamicForm
    });

    const handleWatchDinamicForm = () => {
      dataDOM.wathcDinamicForm = !dataDOM.wathcDinamicForm;
      emit('emitShowDinamicForm', dataDOM.wathcDinamicForm);
    }

    const handleDownloadFile = (field) => {
      getFileDetail(field.route).then((response) => {
        if (response && response.success !== false) { //Si es blob no tiene propiedad success, si falla success es false
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', field.name);
          // Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }).catch(() => {

      })
    }

    return {
      dataDOM,
      handleWatchDinamicForm,
      handleDownloadFile
    };
  },
};

export default ValidationEvents;
</script>
<style scoped>
.button-normal{
  background: #e70e4c;
  color: #fff;
  border-radius: 20px;
  border: none;
  padding: 8px 15px 8px 15px;
  cursor: pointer;
}
.card-title{
  padding: 1rem;
  border-left: 0.5rem solid #3fa7e1;
  background: #f8f8f8;
  border-top: 2px solid #e8e5e5;
  font-weight: bold !important;
}
.card{
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
}
.card-fields{
  padding: 0 !important;
}
.field-title{
    font-weight: 600;
    color: #404040;
    font-family: "Avenir Heavy";
}
.field-text-value{
  color: #7B868C;
  font-family: "Avenir Roman";
}
.text-file{
  color: #E8394D;
}
</style>
